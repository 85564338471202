exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-end-user-license-agreement-js": () => import("./../../../src/pages/end-user-license-agreement.js" /* webpackChunkName: "component---src-pages-end-user-license-agreement-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-grievance-redressal-js": () => import("./../../../src/pages/grievance-redressal.js" /* webpackChunkName: "component---src-pages-grievance-redressal-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-program-js": () => import("./../../../src/pages/international-program.js" /* webpackChunkName: "component---src-pages-international-program-js" */),
  "component---src-pages-masterclass-list-js": () => import("./../../../src/pages/masterclass-list.js" /* webpackChunkName: "component---src-pages-masterclass-list-js" */),
  "component---src-pages-openschool-js": () => import("./../../../src/pages/openschool.js" /* webpackChunkName: "component---src-pages-openschool-js" */),
  "component---src-pages-project-result-js": () => import("./../../../src/pages/project-result.js" /* webpackChunkName: "component---src-pages-project-result-js" */),
  "component---src-pages-realschool-product-js": () => import("./../../../src/pages/realschool-product.js" /* webpackChunkName: "component---src-pages-realschool-product-js" */),
  "component---src-pages-specialeducation-js": () => import("./../../../src/pages/specialeducation.js" /* webpackChunkName: "component---src-pages-specialeducation-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-tourofrealschool-js": () => import("./../../../src/pages/tourofrealschool.js" /* webpackChunkName: "component---src-pages-tourofrealschool-js" */)
}

